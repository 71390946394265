import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Text } from 'theme-ui'
import ProductCard from '~/components/Product/Card'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css'

const ProductCarousel = ({ title, description, products }) => {
  return (
    <Box
      sx={{
        background: '#e5e1de',
        padding: '1rem 0 5rem',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: 'calc(1200px + 3rem)',
          margin: ['0 auto', '36px auto 0']
        }}
      >
        <Heading
          as="h1"
          variant="h1"
          sx={{
            fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
            fontWeight: 'normal',
            fontSize: ['34px', '44px', '55px'],
            textAlign: 'center',
            color: 'primary',
            marginTop: [null, null, '36px'],
            marginBottom: ['8px', null, '32px']
          }}
        >
          {title}
        </Heading>
        {description && (
          <Text
            sx={{
              variant: 'text.body',
              color: 'primary',
              textAlign: 'center'
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html
              }}
            />
          </Text>
        )}
        <Slider
          adaptiveHeight={true}
          autoplay={false}
          infinite={false}
          arrows={true}
          dots={true}
          slidesToScroll={1}
          speed={500}
          lazyLoad={true}
          swipe={false}
          responsive={[
            {
              breakpoint: 640,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true
              }
            },
            {
              breakpoint: 768,
              settings: { slidesToShow: 2 }
            },
            {
              breakpoint: 1024,
              settings: { slidesToShow: 2 }
            },
            {
              breakpoint: 1280,
              settings: { slidesToShow: 3 }
            },
            {
              breakpoint: 100000,
              settings: { slidesToShow: 3 }
            }
          ]}
        >
          {products.map(product => {
            return (<ProductCard key={product.slug} product={product} />)
          })}
        </Slider>
      </Box>
    </Box>
  )
}

ProductCarousel.propTypes = {
  products: PropTypes.array,
}

export default ProductCarousel
