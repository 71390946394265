import { graphql } from 'gatsby'
import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { Box, Flex, Heading, Text, Button } from 'theme-ui'
import ContentHero from '~/components/Content/Hero'
import CollectionSection from '~/components/Content/Section/Collection'
import ProductCarousel from '~/components/Product/Carousel'
import { Hyperlink } from '~/components/Content/RichText'

const constants = {
  backgroundColor: '#265C5E',
  navTextColor: '#91D3CA'
}

const IndexPage = ({ data }) => {
  const page = data.home
  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
    >
      <Metadata title={data.home.name} />
        {page.hero && (
            <Box sx={{
              margin: '1.5rem 1.5rem -13rem',
            }}>
              <ContentHero hero={page.hero} />
            </Box>
        )}
        <Box sx={{
            background: [null, null, '#e5e1de'],
            padding: ['4rem 4rem 10rem', '4rem']
          }}></Box>
        {page.sections &&
          page.sections.map((section, index) => {
            return (
              <Flex
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                {section.media && section.media[0].__typename === 'ContentfulCollection' && (
                  <CollectionSection
                    collections={section.media}
                    title={section.title}
                    description={section.description}></CollectionSection>
                  )}
                {section.media && section.media[0].__typename === 'ContentfulProduct' && (
                  <ProductCarousel
                    products={section.media}
                    title={section.title}
                    description={section.description}
                    cta={section.cta}
                  />
                )}
                {section.slug === 'the-co-creators-of-hiki' && (
                  <Box sx={{
                    background: 'rgb(165, 197, 237)',
                    padding: ['0 1.5rem 3rem'],
                    textAlign: 'center',
                  }}>
                    <Heading
                      as="h2"
                      sx={{
                        color: 'rgb(81, 39, 115)',
                        fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
                        fontWeight: 'normal',
                        fontSize: ['34px', '55px', '55px'],
                        marginTop: ['2rem', null, '3rem'],
                        marginBottom: ['16px', null, '32px'],
                      }}
                    >
                      {section.title}
                    </Heading>
                    <Text
                      sx={{
                        color: 'rgb(81, 39, 115)',
                        fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
                        fontSize: ['14px', '16px', '16px'],
                        marginBottom: '3rem',
                        margin: '0 auto',
                        maxWidth: 'calc(1200px + 3rem)',
                        position: 'relative',
                        '& iframe': {
                          height: ['200px', '400px', '600px'],
                          width: '100%'
                        }
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: section.description.childMarkdownRemark.html
                        }}
                      />
                    </Text>
                    <Hyperlink
                      sx={{
                        color: 'white',
                        display: 'inline-block',
                        backgroundColor: 'rgb(81, 39, 115)',
                        '&:hover': {
                          filter: 'brightness(0.9)',
                        },
                        borderRadius: '0.25rem',
                        fontFamily: 'DIN Alternate Bold,Helvetica,Arial,Sans-Serif',
                        textTransform: 'uppercase',
                        padding: ['0.5rem', '0.5rem 4rem'],
                        marginTop: '2rem',
                        transition: 'filter 0.15s ease-in-out 0s',
                        width: ['100%', 'auto']
                      }}
                      content={section.cta.content[0].content[1].content} data={section.cta.content[0].content[1].data}
                    />
                  </Box>
                )}
              </Flex>
            )
          })}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage($locale: String) {
    home: contentfulPage(slug: { eq: "home" }, node_locale: { eq: $locale }) {
      ...ContentHeroFragment
      ...ContentPageFragment
    }
  }
`
